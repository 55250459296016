.NewsdetailBody {
    display: block;
    position: relative;

    height: auto;

    margin-top: 15px; /* 25px */

    text-align: center;
}

.NewsdetailBody-Content {

    display: inline-block;
    position: relative;

    width: 1110px;
    height: auto;

    text-align: left;
    color: #6e6e6e;
    font-size: 22px;
}

.NewsdetailBody-Content hr{
    /*display: inline-block;*/
    max-width: 80vw;
}
.NewsdetailBody-Content iframe{
    position:absolute;
    width: 100%;
    height: 100%;
}
.NewsdetailBody-Content img{
    /*
    max-width: calc(100% - 10px);
    max-width: 90%;
    width: 90%;
    */
    max-width: 80vw;
    height: auto; /* HCY 2019JUN28 ommit blank under image */

    object-fit: contain;
    object-position: 0 0;
}
.NewsdetailBody-Content table{
    /*width: calc(100% - 10px) !important; */
    max-width: 80vw;
    /*max-width: inherit;*/

    height: auto; 
}


@media only screen and (max-width: 1200px) { 
    .NewsdetailBody-Content{
        width: 90vw;
    }
}