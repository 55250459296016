.RecruitJob {
    display: block;
    position: relative;

    left: 0px;
    top: 0px;

    width: 1128px;

    margin-left: 36px;
    margin-top: 20px;

    /*
    width: 1200px;
    height: 990px;
    text-align: left;
    */

}

.RecruitJob-Cap {
    display: block;
    position: relative;

    left: 0px; /*36px;*/
    top: 0px; /*20px;*/
    width: 1128px;
    height: auto; /*39px;*/

    /*background-image: url('./img/cap_job.png');*/
    /*background-image: url('./img/cap_job_any.png');*/
    background-image: url('./img/cap_dot.png');
    background-repeat: no-repeat;
}


.RecruitJob-Cap-Bar {
    display: block;
    position: relative;
    left: 0px;
    top: 0px;
    width: 1128px;
    height: 5px;

    background-image: url('./img/cap_bar.png');
}

.RecruitJob-Cap-Txt {
    display: block; /* inline-block;*/
    position: relative;

    left: 20px; /*90px;*/
    top: 0px; /*-5px; /*-7px;*/
    width: 1108px; /* 1128px; */
    /*height: 30px;*/

    color: #000000;
    font-size: 28px;
    font-weight: bolder;
    line-height: 33px; /*30px;*/
}

.RecruitJob-Context {
    display: block;
    position: relative;
    left: 0px;
    top: 0px;
}

@media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .RecruitJob-Content table{
        width: 90vw !important;
    }
    .RecruitJob-Cap-Txt{
        width: 80vw;
    }
}
