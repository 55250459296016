
.ServiceInfoGame{
    display: inline-block;
}

.SIG-Context{
    display: inline-block;
    position: relative;

    left: 0px; /*590px;*/
    top: 0px; /*80px;*/
    width: 525px;
    height: auto; /*171px;*/

    margin-left: 10px;
    margin-bottom: 15px;

    text-align: left;
    color: #646464;
    font-size: 18px;
    line-height: 24px;

    overflow: hidden;
}

.SIG-Btns {
    display: inline-block;
    position: relative;

    text-align: center;

    width: 100%;
}


.SIG-Btn-Twitter {
    display: inline-block;
    position: relative;
    left: 0px; /*877px;*/
    top: 0px; /*260px;*/
    width: 172px;
    height: 43px;

    margin-left: 15px;
    margin-right: 15px;

    background-image: url('./img/btn_twitter.png');
}
.SIG-Btn-Twitter:hover {
    background-image: url('./img/btn_twitter_h.png');
}


.SIG-Btn-Website {
    display: inline-block;
    position: relative;
    left: 0px; /* 673px; */
    top: 0px; /*260px;*/
    width: 172px;
    height: 43px;

    margin-left: 15px;
    margin-right: 15px;

    background-image: url('./img/btn_website.png');
}
.SIG-Btn-Website:hover {
    background-image: url('./img/btn_website_h.png');
}
@media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .SIG-Context{        
        width: 100%;
        margin-left: initial;
        margin: 6px;
    }
    .SIG-Btn-Twitter,.SIG-Btn-Website{
        width: 35vw;
        max-width: 172px;
        background-size: 95% 95%;
        background-repeat: no-repeat;
    }
}