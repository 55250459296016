

.NewsPage-Btns {

    position: relative;
  
    overflow: visible;
  
    left: 0vw;
    top: 0px;
    width: 100%;
    /*height: 60px;*/
  
    z-index: 10;
  
  
    vertical-align: middle;
  
    text-align: center;
  
    /*border: 1px solid green; /* #DEBUG */
  }
  
  .NewsPage-Btns-ul {
    display: inline-block;
  
    width: fit-content; 
  
    list-style-type: none;
  
    /*
    overflow: hidden;
    */
    margin: 0;
    padding: 0;
  
    /*border: 1px dotted orchid; /* #DEBUG */
  }
  
  .NewsPage-Btns-li {
    width: 50px;
  
    float: left;
  
    /*border: 1px dotted blue; /* #DEBUG */
  }