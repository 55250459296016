
.LawShop{
    text-align: center;

    margin-bottom: 55px;
}
  
  .LawShop-Cap {
    display: inline-block;
    position: relative;
    left: 0px;
    top: 15px;
    width: 1171px;
    height: 45px;

    background-image: url('./img/cap.png');
}
  
  .LawShop-table {
  
    display: inline-block;
    position: relative;

    left: 0px;
    top: 0px;
    margin-top: 16px; /* 28px; (!) affected by border-spacing */
  
    width:auto;
    
    border-collapse: separate;
    border-spacing: 0px 12px;
 
  }
  
  .LawShop_td_ttl{
    color: #ffffff;
    background-color: #8c1d23;
  
    width: 240px; /* 263px (!) padding 25px  */
    text-align: left;
  
    padding-left: 25px;
    padding-top: 15px;
  
    font-size: 20px;
    font-weight: bold;
  }
  
  .LawShop_td_ctt{
    color: #000000;
    background-color: #ebebeb;

    width: 765px; /* 795px (!) padding 30px */
    text-align: left;
  
    padding-left: 30px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  
    font-size: 20px;
    font-weight: normal;
  }
  

.LawShop-Link{
    color: #1e3f83;
    text-decoration: none;
  }