.debugFYJ{
  border: 0px solid red;
}

.debugFYJ2{
  border: 0px dotted blue;
}

.debugFYJ3{
  border: 0px dashed orchid;
}

.App {
  text-align: center;
  background-color: black;
  
}

.App-logo {
  animation: App-logo-spin infinite 10s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*************************************/

.root {

  display: block;

  /*
  height: 1092px;

  */
  overflow: hidden;

  text-align: center;
}

.bg {

  background-color: white;

  /*
  background-image: url('./bg.png');

  background-size: 1920px 1287px;
  background-position: center top;
  background-attachment: scroll;
  */

  /*height: 1287px;*/
  
  overflow: visible;

  z-index: 10;
}

.root_w{
  /*
  width: 100vw;
  */
}
.bg_w{
  width: 100vw;
}

.middle2{
  display: inline-block;

  position: relative;

  /* top: 0px;   not work ? */
  vertical-align: top;

  width: 1200px;

  overflow:visible;

  z-index: 20;
}
