.NewscapTop {

  /*border: 0px solid orange; /* #DEBUG */

  text-align: left;
  vertical-align: middle;

  padding-top: 20px; /*10px;*/

  overflow: visible;
}

.NewscapTop-Date {
  color: #000000;
  padding-right: 25px;
}


.NewscapTop-Pic-New {
    position: relative;

    padding-right: 18px;

    top: 5px;

    width : 98px;
    height: 24px;
    pointer-events: none;
  }

.NewscapTop-Title {
    position: relative;

    padding-left: 0px;

    font-size: 18px;
    color: black;
    text-decoration: none;
    /*text-align: center;*/
  }
@media only screen and (max-width: 1200px) {
  .NewscapTop-Date {
    padding-right: 5px;
    padding-left: 5px;
    font-weight: 800;
  }
  .NewscapTop-Pic-New {
    padding-right: 5px;
    
    
  }
  .NewscapTop {
    /*border: 0px solid orange; /* #DEBUG */  
    text-align: left;
    vertical-align: middle;
    padding-top: 10px; /*10px;*/
    overflow: hidden;
    display: inline-table;
  }
}