

.TopNews {

    /*
    display: inline-block; xxxxxx
    */
    display: block;
    position: absolute;

    left: 20px;
    top: 418px;
    width: 691px;
    /*height: auto; /* xxx */
    height: 570px; /**/
}

.TopNews-Cap {
    position: relative;
    left: -17px;
    top: 0px;
  }

.TopNews-Btn-More {
    display: inline-block;
    position: absolute;
    left: 620px;
    top: 5px;
    width: 67px;
    height: 15px;

    background-image: url('./img/btn_top_news_more.png');
}
.TopNews-Btn-More:hover {
    background-image: url('./img/btn_top_news_more_h.png');
}
@media only screen and (max-width: 1200px) {
    .TopNews {
        display: block;
        position: initial;
    
        left: initial;
        top: initial;
        width: 100%;
        
        height: auto; /**/
    }
    
    .TopNews-Cap {
        margin-top: 2px;
        top: 0px;
        left: initial;
        width: 99%;
        max-width: 709px;
        height: auto;
        position: initial;
        
      }
    
    .TopNews-Btn-More {
        position: absolute;
        left: initial;
        margin-left: -80px;
        top: 2px;
        width: 67px;
        height: 15px;
    
        background-image: url('./img/btn_top_news_more.png');
    }
    
  }