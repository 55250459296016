
.BodyFYJ{
    
    border: 0px dotted greenyellow;

    background-image: url('./img/bg.png');

    background-size: 634px 496px;
    /*background-position: right bottom;*/
    /*background-position: 1073px bottom;*/
    /*background-position: 115% bottom;*/
    background-position: 55vw bottom;
    background-attachment: scroll;

    background-repeat: no-repeat;

    width: 100vw;
    text-align: center;

    overflow: hidden;
}
@media only screen and (max-width: 1200px) {
}