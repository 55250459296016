.RecruitFYJ {
    display: block;
    position: relative;

    /*
    position: absolute; // seems xxxxx
    */

    left: 0px;
    top: 0px;

    width: 1200px;
    height: auto; /*990px;*/

    text-align: left;

    color: #000000;
}

.RecruitFYJ-Txt {
    display: inline-block;
    position: relative;
    left: 55px;

    color: #646464;
}

.RecruitFYJ-Cap {
    display: inline-block;
    position: relative;
    left: 15px;
    top: 15px;
    width: 1171px;
    height: 45px;

    background-image: url('./img/cap.png');
}

.RecruitFYJ-Cap-New {
    display: inline-block;
    position: relative;
    left: 36px;
    top: 20px;
    width: 1128px;
    height: 33px;

    background-image: url('./img/cap_new.png');
}


.RecruitFYJ-Cap-Career {
    display: inline-block;
    position: relative;
    left: 36px;
    top: 20px;
    width: 1128px;
    height: 33px;

    background-image: url('./img/cap_career.png');
}

.RecruitFYJ-Cap-List {
    display: inline-block;
    position: relative;
    left: 0px;
    top: 0px;

    width: auto; /*1128px;*/
    height: auto;
}

.RecruitFYJ-Resume {
    display: block;
    position: relative;

    left: 0px;
    top: 0px;

    margin-left: 36px;
    margin-top: 20px;
    margin-bottom: 25px;
}

.RecruitFYJ-Cap-Resume {
    display: inline-block;
    position: relative;
    left: 0px;
    top: 0px;
    width: 1128px;
    height: 39px;

    background-image: url('./img/cap_resume_address.png');
}
@media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .RecruitFYJ-Cap {
        left: -1px;
    }
}  