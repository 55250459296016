.TopFYJ {
    text-align: left;
}

.TopFYJ-bg-upper {

    background-color: #ebebeb;

    /*
    background-image: url('./bg.png');
  
    background-size: 1920px 700px;
    background-position: center top;
    background-attachment: scroll;
    background-repeat: no-repeat;
    */
    left: 15px;
    top: 11px;
    width: 1170px;
    height: 390px;
    
    overflow: visible;
  
    z-index: 10;
}

.TopFYJ-bg-bottom {

    /*
    display: block;
    position: relative;
    */

    /*border: 1px solid blue;*/

    left: 15px;
    top: 420px;
    width: 1170px;
    height: 575px; /* */
    /*height: fit-content; /* xxx */
    /*height: auto; /* xxxx */
    
    overflow: visible;
  
    z-index: 10;
}
@media only screen and (max-width: 1200px) {
    .TopFYJ {
        text-align: center;
    }
    .TopFYJ-bg-upper {
        display: block;
        text-align: center;
        background-color: #ebebeb;
    
        /*
        background-image: url('./bg.png');
      
        background-size: 1920px 700px;
        background-position: center top;
        background-attachment: scroll;
        background-repeat: no-repeat;
        */
        left: 0px;
        top: 11px;
        width: 100vw;
        height: 100%;
      overflow: hidden;
    }
    
    .TopFYJ-bg-bottom {
    
        display: block;
        position: relative;
    
        border: 1px solid blue;
    
        left: inherit;
        top: initial;
        width: 100vw;
        height: 100%; /* */
        /*height: fit-content; /* xxx */
        /*height: auto; /* xxxx */
        overflow-x: hidden;
        overflow-y: hidden;
        
      
        z-index: 10;
    }
}