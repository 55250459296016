
.TopBanner{
    display: block;

    width: 414px;
    /*height: 125px;*/

    margin-bottom: 6px;
}

.TopBanner-Btn{
    display: block; /* (!) <Link /> <a /> default is inline */

    /*
    width: 414px;
    height: 125px;
    */

    /*margin-bottom: 6px;*/
}

.TopBanner-Img{
    width: 414px;
}
@media only screen and (max-width: 1200px) {
    .TopBanner{    
        width: 100%;
    }
    .TopBanner-Img{
        width: 100%;
        max-width: 414px;
    }        
}