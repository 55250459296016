.NewsdetailFooter {
    display: block;
    position: relative;

    height: 125px;

    text-align: center;
}


.NewsdetailFooter-Btn-Prev {
    display: inline-block;
    position: absolute;
    left: 40px;
    top: 0px;
    width: 120px;
    height: 30px;

    background-image: url('./img/btn_prev.png');
}
.NewsdetailFooter-Btn-Prev:hover {
    background-image: url('./img/btn_prev_h.png');
}


.NewsdetailFooter-Btn-Top {
    display: inline-block;
    position: absolute;
    left: 522px;
    top: 0px;
    width: 120px;
    height: 30px;

    background-image: url('./img/btn_top.png');
}
.NewsdetailFooter-Btn-Top:hover {
    background-image: url('./img/btn_top_h.png');
}


.NewsdetailFooter-Btn-Next {
    display: inline-block;
    position: absolute;
    left: 1040px;
    top: 0px;
    width: 120px;
    height: 30px;

    background-image: url('./img/btn_next.png');
}
.NewsdetailFooter-Btn-Next:hover {
    background-image: url('./img/btn_next_h.png');
}
@media only screen and (max-width: 1200px) { 
    .NewsdetailFooter-Btn-Top{
        left: initial;
        position: relative;
        margin-top: 25px;
        margin-left: 15px;
        margin-right: 15px;
        background-size: contain;
        width: 90px;
        height: 23px;
    }
    .NewsdetailFooter-Btn-Next {
        left: initial;
        position: relative;
        margin-top: 25px;
        background-size: contain;
        width: 90px;
        height: 23px;

    }
    .NewsdetailFooter-Btn-Prev {
        left: initial;
        position: relative;
        margin-top: 25px;
        background-size: contain;
        width: 90px;
        height: 23px;

    }
}