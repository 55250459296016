.Contract {
    color: gray;
    text-align: center;
}

.Contract-Cap {
    position: relative;
    left: 0px;
    /*top: 15px;*/
    margin-top: 15px;
}

.Contract-Pic-Abs {
  display: inline-block;
  position: relative;
  left: 0px;
  top: 0px;
  width: 1128px;
  height: 32px;

  margin-top: 25px;

  background-image: url('./img/cap_abs.png');
}

.Contract-Txt-Abs {
    color: black;

    /*width: 1090px;*/

    text-align: center;

    font-size: 20px;
    font-weight: bold;

    margin-left: 40px;
    margin-right: 40px;
}

.Contract-Body {
  text-align: left;
}

.Contract-Txt-Cap {
    /*border: 1px dashed blue;*/

    width: 100%;

    display: inline-block;
    position: relative;

    color: black;
    text-align: left;
    font-size: 20px;
    font-weight: bold;

    padding-left: 55px;
}
  
.Contract-Txt {
    /*border: 1px dotted olive;*/

    display: inline-block;
    position: relative;

    color: #6e6e6e;
    text-indent: -20px;
    text-align: left;
    font-size: 20px;

    left: 0px;
    /*width: 1045px;*/
    margin-left: 80px;
    margin-right: 80px;

    /*
    text-indent: 0px;
    padding-left: 75px;
    padding-right: 75px;
    */
  }
  
  .Contract-Txt-Mail {
      color: #1e3f83;
      text-decoration: none;
    }
  
  .Contract-Txt-Date {

    width: 100%;

    display: inline-block;
    position: relative;

    /*left: 55px;*/
    padding-left: 55px;

    color: black;
    text-align: left;
    font-size: 22px;
    font-weight: bold;
  }

.Contract-Txt-Hisi {
  text-indent: -20px;
  left: 0px;
  margin-left: 20px;
}

.Contract-Txt-Rule10 {
  text-indent: 0px;
  margin-left: 60px;
}
@media only screen and (max-width: 1200px) {    
  /* For mobile phones: */

  .Contract-Txt, .Contract-Txt-Abs {
    width: 85vw;
    left: 10vw;
    margin: auto;
    text-align: left;
  }
  .Contract-Txt-Cap ,.Contract-Txt-Date{
    width: 90vw;
    padding:5vw;
    display: block;
  }
}