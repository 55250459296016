
.NewsFYJ{
    text-align: center;
  
    height: auto; /*1070px;*/

    padding-bottom: 50px;
  }
  
.News-Cap {
    display: inline-block;
    position: relative;
    left: 0px;
    top: 15px;
    width: 1171px;
    height: 45px;

    background-image: url('./img/cap.png');
}
  
.News-table {
  
    /*border: 0px dotted purple; /* #DEBUG */
  
    display: inline-block;
    position: relative;
    left: 0px;
    top: 20px; /*35px; (!) affected by border-spacing */
  
    /*width:55vw;*/
    width:auto;
  
    
    /*cellpadding:*/
    border-collapse: separate;
    border-spacing: 0px 10px;
    
    /*="10" background-color="#F1DFDF"*/
  
  }

/*  
https://stackoverflow.com/questions/10040842/add-border-bottom-to-table-row-tr
*/
.News-tr td{

    /*border: 1px dotted purple;*/
    /*border-style: dotted dashed solid double;*/

    border-width: 1px;

    border-color: #c2c2c2;

    border-top-style: none;
    border-right-style: none; /*dotted*/
    border-bottom-style: solid;
    border-left-style: none;

}
  
  .News-td_date{
    color: #000000;

    /*background-color: #eeffee;*/
  
    width: 135px; /* 150px (!) padding 15px */
    text-align: left;
  
    padding-left: 15px;
    /*padding-top: 15px;*/
  
    font-size: 20px;
    font-weight: bold;

  }

  .News-td_icon{
    width: 98px;
    height: 24px;

    background-image: url('./img/news_icon.png');
    background-position: center top;
    background-repeat: no-repeat;
  }
  .News-td_icon_hide{
    width: 98px;
    height: 24px;
  }
  
  .News-td_cap{
    color: #000000;
    /*background-color: #ebebeb;*/
    width: 870px; /*860px;*/
    text-align: left;
  
    padding-left: 30px;
    /*padding-top: 15px;*/
    padding-bottom: 15px;
  
    font-size: 20px;
    font-weight: normal;
  }
  
  .News-td_btns{
    /*
    display: inline-block;
    position: relative;
    left: -10px;
    */

    padding-top: 30px;
  
    padding-left: 0px;

    border-bottom-style: none;
  }
@media only screen and (max-width: 1200px) {
  .News-td_date{
    width: auto; /* 150px (!) padding 15px */
    text-align: left;
  font-size: initial;
    padding-left: 1vw;
  }
  .News-td_icon{
    width: 50px;
  }
  .News-td_cap{
    color: #000000;
    /*background-color: #ebebeb;*/
    width: auto; /*860px;*/
    text-align: left;
    font-size: initial;
    padding-left: initial;
    display: block;
  }
}