
.ComLoc{
    display: inline-block;
    position: relative;
    width: auto; /*840px;*/
    height: 475px;
}

.ComLoc-Map{
    background-color: #FFFFFF;

    display: inline-block;
    position: relative;

    left: 0px;
    top: 0px;

    /*
    width: 613px;
    height: 353px;
    */
    width: 800px;
    height: 460px;

    padding-left: 10px;
    padding-top: 7px;
}

.ComLoc-Map-iframe{
    width: 789px;
    height: 432px;
    /*
    width: 602px;
    height: 325px;
    */

    margin: 0 0 0 0;
}

.ComLoc-Cap{
    display: inline-block;
    position: relative;
    left: 0px;
    top: 10px;
    width: 800px;
    height: 32px;

    background-image: url('./img/cap_access.png');
}

.ComLoc-Txt{
    display: inline-block;
    position: relative;
    left: 20px;
    top: 10px;
    /*
    width: 800px;
    height: 32px;
    */

    color: #000000;
    font-size: 18px;
}


.ComLoc-Map-Link{
    display: inline-block;
    position: relative;
    left: 0px;
    top: -10px;

    color:#0000FF;
    text-align:left;

    font-size: 12px;
}
@media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .ComLoc-Map{
        width: 97%;
        padding-left: 1%;
    }    
    .ComLoc-Map-iframe{
        width: 100%;
    }
    .ComLoc-Txt{
        left: initial;
    }
.ComLoc,.ComLoc-Cap    {
width:88vw;
    }
}  