

.TopPics {
    display: inline-block;
    position: absolute;
    left: 20px;
    top: 18px;
    width: 691px;
    height: 375px;
}

.TopPics-Pics {
    width: 691px;
    height: 350px;
    pointer-events: none;
}


.TopPics-Btns {

  position: absolute;

  overflow: visible;

  left: 0vw;
  top: 348px; /*360px;*/
  width: 100%;
  /*height: 60px;*/

  z-index: 10;


  vertical-align: middle;

  text-align: center;

  border: 0px solid green; /* #DEBUG */
}

.TopPics-Btns-ul {
  display: inline-block;

  width: fit-content; 

  list-style-type: none;

  /*
  overflow: hidden;
  */
  margin: 0;
  padding: 0;

  border: 0px dotted orchid; /* #DEBUG */
}

.TopPics-Btns-li {
  width: 20px;

  float: left;

  border: 0px dotted blue; /* #DEBUG */
}
@media only screen and (max-width: 1200px) {
  .TopPics-Btns-li {
    display: none;
  }
  .TopPics {
    display: block;
    left: initial;
    top: 18px;
    width: 100%;
    height: auto;
    position: initial;
  }
  .TopPics-Pics {
    width: 99%;
    max-width: 691px;
    height: auto;
  }
}