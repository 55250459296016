
.TopPics-Btn{
    display: inline-block;
    width: 8px;
    height: 8px;
    border: 0px solid blueviolet; /* #DEBUG */
    background-image: url('./img/pic_btn.png');
    background-size: 100% 100%;
  }
.TopPics-Btn:hover{

    background-image: url('./img/pic_btn_h.png');
  }
.TopPics-Btn-Active{
    border: 0px solid red; /* #DEBUG */
    background-image: url('./img/pic_btn_h.png');
  }
