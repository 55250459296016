

.ContactForm{
    text-align: center;

    margin-bottom: 20px;
}


.Contact-RedDot{
    color: #a1080f;
    font-size: 18px;
}
.Contact-Mail-Re{
  font-size: 16px;
  color: #6f6f6f;
}


.Contact-Must{
  display: inline-block;
  position: relative;

  color: #a1080f;
  font-size: 20px;

  margin-top: 15px;
}

.Contact-Caution {
    display: inline-block;
    position: relative;

    left: 0px;
    top: 0px;
    width: 1103px;
    height: 236px;

    /* try reserved this space for error message */
    /*margin-top: 35px;*/

    background-image: url('./img/bg_caution.png');
    background-position: center top;
    background-repeat: no-repeat;
}

.Contact-Caution-Txt {
    color: #000000;

    display: block;
    position: relative;

    left: 272px;
    top: 16px; /*20px;*/
    width: 804px;
    height: 201px;

    text-align: left;
    line-height: 29px;
    font-size: 22px;
    

}

  
  .Contact-table {
  
    display: inline-block;
    position: relative;

    left: 0px;
    top: 0px;
    margin-top: 16px; /* 28px; (!) affected by border-spacing */
  
    width:auto;
    
    border-collapse: separate;
    border-spacing: 0px 30px;
 
  }
  
  .Contact_td_ttl{
    color: #000000;

    /*background-color: #ffeeff;*/
  
    width: 250px; /*240px; /* 263px (!) padding 25px  */
    text-align: left;
  
    padding-left: 4px; /*25px;*/
    padding-top: 15px;
  
    font-size: 22px;
    font-weight: bold;
  }
  .CttMr{
    padding-top: 0px;
  }
  .Contact-Mail-Re-Root{
    position: relative;
    top: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 24px;
  }
  
  .Contact_td_ctt{
    /*background-color: #ebebeb;*/
    /*background-image: url('./img/bg_input.png');*/

    width: 837px;
    height: 50px;
  
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;

    margin-left: 0px;
 
  }

  .Contact_td_input{
    /*background-color: none;*/
    background-image: url('./img/bg_input.png');
    background-repeat: no-repeat;

    width: 800px; /* 837 -17 -20 (padding-left: 17px right 20px) */
    height: 50px;

    color: #6f6f6f;

    text-align: left;

    padding-left: 17px;
    padding-top: 0px; /*13px;*/
    padding-right: 20px; /* (!) affect by width.. */

    margin-left: 0px;
    margin-top: 0px;

    font-size: 22px;
    font-weight: normal;

    outline: none;
    /*border: 0px solid red;*/
  }

  .Contact_tr_context{
    /*border: 0px solid red;*/
    top: -10px;
  }

  .Contact_td_context{

    width: 837px;
    height: 200px;
 
  }

  .Contact_textarea_context{
    background-image: url('./img/bg_textarea.png');
    background-repeat: no-repeat;

    overflow: hidden; /* scrollbar still annoying */

    resize: none;

    width: 800px; /* 837 -17 -20 (padding-left: 17px right 20px) */
    height: 170px; /* 200 -15 -15 (padding-top/bottom) */

    color: #6f6f6f;

    text-align: left;

    padding-left: 17px;
    padding-right: 20px;
    padding-top: 15px; /*13px;*/
    padding-bottom: 15px; /**/

    margin-left: 0px;
    margin-top: 0px;
    /*margin-bottom: 200px; */

    font-size: 22px;
    font-weight: normal;

    outline: none;
    /*border: 0px solid red;*/

  }


.Contact-Link{
    color: #1e3f83;
    text-decoration: none;
  }

.Ctt-Checkbox-Privacy{
    display: inline-block;
    position: relative;

    top: 5px;
    /*margin-top: 10px;*/
    margin-right: 10px;

    width: 24px;
    height: 24px;

    cursor: pointer;
}
.Contact-Checkbox-Privacy{
    background-image: url('./img/privacy_checkbox.png');
}
.Contact-Checked-Privacy{
    background-image: url('./img/privacy_checked.png');
}


.Ctt-Btn-GoCfm {
    display: inline-block;
    position: relative;
    left: 0px;
    top: 0px;
    width: 192px;
    height: 63px;

    margin-top: 30px;

    cursor: pointer;
}
.Contact-Btn-GoCfm {
    background-image: url('./img/btn_go_confirm.png');
}
.Contact-Btn-GoCfm:hover {
    background-image: url('./img/btn_go_confirm_h.png');
}
.Contact-Btn-GoCfm-Gray {
  background-image: url('./img/btn_go_confirm_g.png');
}

.Contact-Txt-Privacy{
  font-size: 22px;
  margin-bottom: 10px;
}

.Contact-Btn-Privacy{
  font-size: 20px;
  /*line-height: 20px;*/
}

.Contact-Link-Privacy{
  color: black;
  /*text-decoration: none;*/
}

@media only screen and (max-width: 1200px) {    
  /* For mobile phones: */
  .Contact-Caution {
    display: inline-block;
    background-image: none;
    position: relative;
    left: 0px;
    top: 0px;
    width: auto;
    height: 236px;
  }
  .Contact-Caution-Txt{
    left: initial;
    width: auto;
    margin-left: 5vw;
    margin-right: 5vw;
    height: auto;
    font-size: initial;
    top: auto;
    
  }
  .Contact_textarea_context{
    width: 80vw;
    padding-left: 5px;
    padding-right: 5px;
    background-image: none;
    background-color: whitesmoke;
    outline: double;  
    overflow-y: scroll;
  }
  .Contact_td_context{
    width: 90vw;
    padding-left: initial;
    padding-right: initial;
    background-image: none;

  }
  .Contact-Mail-Re-Root{
    top: 0px;
    line-height: initial;
  }
  .Contact-Caution br,.Contact-Mail-Re-Root br{
    display: none;
  }
  .Contact-table tr,.Contact-table td{
    display: block;
  }
  .Contact_td_ttl{
    width: initial;
  }
  .Contact_td_ctt{
    width: initial;
  }
  .Contact_td_input{
    width: initial;
    background-image: none;
    background-color: whitesmoke;
    outline: double;  

  }
  .CttMr{
    padding-top: 15px;
  }
  .Contact-Btn-Privacy{
    width: 90vw;
    margin: auto;
  }

}