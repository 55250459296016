.ServiceDesign {
    display: block;
    position: relative;

    left: 0px;
    top: 0px;
    width: 1200px;
    height: auto;

    text-align: center;
}

.SD-cap {
    display: inline-block;
    position: relative;

    margin-left: 42px;
    margin-top: 25px;
    margin-bottom: 20px;

    left: 0px;
    top: 0px;
    width: 100%;
    height: 24px;

    background-image: url('./img/cap_des_social.png');
    background-position: left top;
    background-repeat: no-repeat;
}

.SD-Row {
    display: block;
    padding-bottom: 33px;
}

.SD-img {
    display: inline-block;
}
@media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .SD-cap{
        width: 90%;
        max-width: 560px;
        background-size: contain;
        margin-left: initial;
    }
    .ServiceDesign {
        width: 100vw;
    }
    .SD-img {
        width: 100%;
    }
}