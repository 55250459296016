
.ServiceInfoDev{
    display: inline-block;
}

.SID-Body{
    display: inline-block;
    position: relative;

    margin-left: 8px;
    margin-bottom: 13px;

}

.SID-Context{
    display: inline-block;
    position: relative;

    left: 0px;
    top: 0px;
    width: 530px; /*303px;*/
    height: auto; /*166px;*/

    text-align: left;
    color: #646464;
    font-size: 16px; /*18px;*/
    line-height: 24px;

    overflow: hidden;
}

/*
.SID-Pic-Dev{
    display: inline-block;
    position: relative;

    left: 0px;
    top: 0px;
    width: 207px;
    height: 187px;
}
*/
.SID-Pic-Dev{
    display: inline-block;
    position: relative;

    left: 0px;
    top: 0px;
    width: auto;
    height: auto;

    vertical-align: top;
}

.SID-Pic-Com{
    display: inline-block;
    position: relative;

    left: 0px;
    top: 0px;
    width: 544px;
    height: auto; /*48px;*/
}

.SID-Txt-Com{
    font-weight: bold;
    color: #000000;
    background-color: #ebebeb;

    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}
@media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .SID-Context{        
        width: calc(100% - 18px) !important;
        margin-left: initial;
        position: initial;
        display: block;
        overflow: initial;
    }
    .SID-Context > br {
        display: none !important;
    }
    .SID-Pic-Dev{
        width: 90%;
        float: left;
    }
    .SID-Pic-Dev::after{
        content:"";
        display: block;
        clear: both;
    }
    .SID-Txt-Com{
        width: 95%;
        padding-left: initial;
        padding-top: initial;
        display: block;
        
    }
}