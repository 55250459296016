
.ServiceTabs {
    display: inline-block;
    position: relative;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 70px;

    border: 0px dotted olive;

    margin-top: 20px;
    /*padding-top: 55px;*/

    text-align: center;
}

.ServiceTabs-ul {
    display: inline-block;
  
    width: fit-content; 
  
    list-style-type: none;
  
    /*
    overflow: hidden;
    */
    margin: 0;
    padding: 0;
  
    border: 0px solid orchid; /* #DEBUG */
  }
  
  .ServiceTabs-li {
    float: left;
  }
  @media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .ServiceTabs-li {
      float: initial;
    }
}