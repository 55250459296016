

.svc_tab {
    display: inline-block;
    position: absolute;

    background-size: 100% 100%;
  
    z-index: 20;
  }

.svc_tab_game{
    left: 74px;
    top: 0px;
    width: 325px;
    height: 54px;
    cursor: pointer;
  }

.svc_tab_design{
    left: 438px;
    top: 0px;
    width: 325px;
    height: 54px;
    cursor: pointer;
}

.svc_tab_dev{
    left: 800px;
    top: 0px;
    width: 325px;
    height: 54px;
    cursor: pointer;
}





.svc_tab_icon_game{
    background-image: url('./img/tab_game.png');
  }
  .svc_tab_icon_game:hover{
    background-image: url('./img/tab_game_h.png');
  }
  .svc_tab_icon_game_selected{
    background-image: url('./img/tab_game_h.png');
  }


.svc_tab_icon_design{
    background-image: url('./img/tab_design.png');
  }
  .svc_tab_icon_design:hover{
    background-image: url('./img/tab_design_h.png');
  }
  .svc_tab_icon_design_selected{
    background-image: url('./img/tab_design_h.png');
  }

.svc_tab_icon_dev{
    background-image: url('./img/tab_dev.png');
  }
  .svc_tab_icon_dev:hover{
    background-image: url('./img/tab_dev_h.png');
  }
  .svc_tab_icon_dev_selected{
    background-image: url('./img/tab_dev_h.png');
  }

  @media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .svc_tab {
      display: block;
      position: initial;
      width: 100vw;
    }
    .svc_tab_icon_game,.svc_tab_icon_design,.svc_tab_icon_dev{
      width: 100vw;
    }
    .svc_tab_icon_game_selected,.svc_tab_icon_game:hover{
      background-repeat: no-repeat;
      background-size: 90% 90%;
      background-image: url('./img/cap.png');
      width: 1000px;
    }
    .svc_tab_icon_design_selected,.svc_tab_icon_design:hover{
      background-repeat: no-repeat;
      background-size: 90% 90%;      
      background-image: url('./img/cap_design.png');
      width: 1000px;
    }
    .svc_tab_icon_dev_selected,.svc_tab_icon_dev:hover{
      background-repeat: no-repeat;
      background-size: 90% 90%;      
      background-image: url('./img/cap_dev.png');
      width: 1000px;

    }
  
  }
