.HeaderFYJ {

    /*left: 0px;*/
    top: 0px;
    width: 1200px; /*1920px;*/
    height: 109px;
  
    text-align: left;
}

/* HCY 2019AUG06 Deprecated, replaced by HeaderButton  */
.HeaderFYJ-logo {
    position: absolute;
    left: 34px; /* 394 -360 px*/
    top: 17px;
    width: 248px;
    height: 77px;
    pointer-events: none;
}
@media only screen and (max-width: 1200px) {
    /* For mobile phones: */
.HeaderFYJ{
    width: 100vw;
    max-height: 95px;
}

  }
