.Newsdetail {
    display: block;
    position: relative;

    /*
    position: absolute; // seems xxxxx
    */

    left: 0px;
    top: 0px;

    width: 1200px;
    height: auto;

    text-align: center;
}

.Newsdetail-Bar-Footer{
    width : 1170px;
    margin-bottom: 24px;

    /*color: #FF0000; /* X */
    border-color: #c0c0c0;
}
@media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .Newsdetail,.Newsdetail-Bar-Footer {
        width: 90vw;
        margin: auto;
    }
}  