
.ContactFYJ{
    color: #000000;
    
    text-align: center;

    margin-bottom: 35px;
}

.Contact-Cap {
    display: inline-block;
    position: relative;

    left: 0px;
    top: 0px;
    width: 1171px;
    height: 45px;

    margin-top: 15px;

    background-image: url('./img/cap.png');
}

.Contact-Err {
    color: #FF0000;
    font-size: 22px;
    height: 35px;
}