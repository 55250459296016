

.TopBanners {

    /*
    display: inline-block;
    */
    display: block;
    position: absolute;

    left: 735px;
    top: 418px;
    width: 420px;
    /*height: auto; /* xxx */
    height: 570px; /**/

    /*overflow: hidden;*/
}


.TopBanners-Cap {
    display: inline-block;
    position: relative;
    left: 0px;
    top:  0px;
    width: 414px;
    height: 32px;

    background-image: url('./img/cap_pickup.png');
}

.TopBanners-Body{
    display: inline-block;
    position: relative;
    left: 0px;
    top:  0px;
    width: 425px;
    height: 425px;

    /*overflow: hidden;*/

    margin-top: 15px;
}

.TopBanners-Pic{
    display: block; /* (!) <Link /> <a /> default is inline */

    /*background-size: 100% 100%;*/

    width: 414px;
    height: 125px;

    margin-bottom: 6px;
}
@media only screen and (max-width: 1200px) {
    .TopBanners{
        display: block;
        position: initial;
        left: initial;
        top: initial;
        width: 100%;
        height: auto;             
    }
    .TopBanners-Cap {
        display: block;
        position: initial;
        left: initial;
        top:  initial;
        margin: auto;
        width: 100%;
        max-width: 414px;
        height: 32px;    
    }

    .TopBanners-Body{
        display: inline-block;
        position: relative;
        left: 0px;
        top:  0px;
        width: 100%;
        height: auto;
    
        /*overflow: hidden;*/
    
        margin-top: 15px;
    }
    
}