.ServiceFYJ {
    display: block;
    position: relative;
    /*
    position: absolute; // seems xxxxx
    */

    left: 0px;
    top: 0px;

    width: 1200px;
    /*height: 990px;*/
    height: auto;

    padding-top: 15px;
    padding-bottom: 70px;

    text-align: center;
}

.ServiceCapGame {
    display: inline-block;
    position: relative;
    left: 0px;
    top: 0px;
    width: 1171px;
    height: 45px;

    background-image: url('./img/cap.png');
}

.ServiceCapDesign {
    display: inline-block;
    position: relative;
    left: 0px;
    top: 0px;
    width: 1171px;
    height: 45px;

    background-image: url('./img/cap_design.png');
}

.ServiceCapDev {
    display: inline-block;
    position: relative;
    left: 0px;
    top: 0px;
    width: 1171px;
    height: 45px;

    background-image: url('./img/cap_dev.png');
}
@media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .ServiceFYJ {
        width: initial;
    }
    .ServiceCapGame ,.ServiceCapDesign,.ServiceCapDev{
        display: none;
    }
  }
