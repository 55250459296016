
.CompanyFYJ{
  text-align: left;

  height: 1070px; /*995px;*/
}

.ComFYJ-Cap {
  position: relative;
  left: 15px;
  top: 15px;
}

.ComFYJ-table {

  display: inline-block;
  position: relative;
  left: 70px;
  top: 20px; /*35px; (!) affected by border-spacing */

  /*width:55vw;*/
  width:auto;

  
  /*cellpadding:*/
  border-collapse: separate;
  border-spacing: 0px 12px;
  
  /*="10" background-color="#F1DFDF"*/

}

.ComFYJ_td_ttl{
  color: #ffffff;
  background-color: #8c1d23;
  /*width: 11vw;*/

  width: 187px; /* 212px (!) padding 25px OTL .. */
  text-align: left;

  padding-left: 25px;
  padding-top: 15px;

  font-size: 20px;
  font-weight: bold;
}

.ComFYJ_td_ctt{
  color: #000000;
  background-color: #ebebeb;
  /*width: 44vw;*/
  width: 818px; /* 848px (!) padding 30px OTL .. */
  text-align: left;

  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;

  font-size: 20px;
  font-weight: normal;
}

.ComFYJ_td_map{
  /*
  display: inline-block;
  position: relative;
  left: -10px;
  */

  padding-left: 20px;
}
@media only screen and (max-width: 1200px) {    
  /* For mobile phones: */
  .ComFYJ-Cap {
    left: -1px;
  }
  .CompanyFYJ{
    height: initial;
  }
  .ComFYJ-table {
    left: 0px;
  }
  .ComFYJ_td_ctt{
    width: 88vw;
    display: table-cell;
    padding-left: 2vw;
  }
  .ComFYJ_td_ttl{
    width: 10vw;
    padding: 0vw;
    display: table-cell;
    text-align: center;
  }
  .ComFYJ_td_ctt{
    width: 88vw;
  }
  .ComFYJ_td_map{
    padding-left: 2vw;
  }
}