.ContactSent{
    text-align: center;

    margin-bottom: 55px;
}


.ContactSent-Caution {
    display: inline-block;
    position: relative;

    left: 0px;
    top: 0px;
    width: 1028px;
    height: 206px;

    margin-top: 35px;

    background-image: url('./img/bg_sent.png');
    background-position: center top;
    background-repeat: no-repeat;
}

.ContactSent-Caution-Txt {
    color: #000000;

    display: block;
    position: relative;

    left: 27px;
    top: 70px;
    width: 978px;
    height: 110px;

    text-align: left;
    line-height: 29px;
    font-size: 22px;

}
@media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .ContactSent-Caution {
        background-image: none;
        width: 100%;
        margin: initial;
        height: initial;        
        position: initial;
        text-align: center;

    }
    .ContactSent{
        margin-bottom: initial;
        top: initial;
        width: 100vw;
        position: initial;
    }
    .ContactSent-Caution-Txt {
        top: initial;
        width: 90%;
        position: initial;
        height: initial;
        padding-left: 3%;
        word-break: break-all;
    }
}  