.ServiceDev {
    border: 0px dashed greenyellow;

    display: block;
    position: relative;

    left: 0px;
    top: 0px;

    width: 1200px;
    height: auto;
    /*height: 990px;*/

    text-align: center;
}
@media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .ServiceDev{
        width: 100vw;
    }
}