
.hdr_btn {
    display: inline-block;
    position: absolute;
    background-size: 100% 100%;
    z-index: 20;
  }
.hdr_btn_top{
    left: 34px; /* 394 -360 px*/
    top: 17px;
    width: 248px;
    height: 77px;
    cursor: pointer;

  }

.hdr_btn_news{
    left: 404px; /* 764px */
    top: 0px;
    width: 158px;
    height: 109px;
    cursor: pointer;
}

.hdr_btn_company{
    left: 562px; /*922px;*/
    top: 0px;
    width: 158px;
    height: 109px;
    cursor: pointer;
}

.hdr_btn_service{
    left: 720px; /*1080px;*/
    top: 0px;
    width: 158px;
    height: 109px;
    cursor: pointer;
}

.hdr_btn_recruit{
  left: 878px; /*1238;*/
  top: 0px;
  width: 158px;
  height: 109px;
  cursor: pointer;
}

.hdr_btn_contact{
  left: 1036px; /*1396;*/
  top: 0px;
  width: 158px;
  height: 109px;
  cursor: pointer;
}



.hdr_icon_top{
  background-image: url('./img/logo.png');
}
.hdr_icon_top_selected{
  background-image: url('./img/logo.png');
}

.hdr_icon_news{
    background-image: url('./img/btn_news.png');
  }
  .hdr_icon_news:hover{
    background-image: url('./img/btn_news_h.png');
  }
  .hdr_icon_news_selected{
    background-image: url('./img/btn_news_h.png');
  }


.hdr_icon_company{
    background-image: url('./img/btn_company.png');
  }
  .hdr_icon_company:hover{
    background-image: url('./img/btn_company_h.png');
  }
  .hdr_icon_company_selected{
    background-image: url('./img/btn_company_h.png');
  }

.hdr_icon_service{
    background-image: url('./img/btn_service.png');
  }
  .hdr_icon_service:hover{
    background-image: url('./img/btn_service_h.png');
  }
  .hdr_icon_service_selected{
    background-image: url('./img/btn_service_h.png');
  }



  .hdr_icon_recruit{
    background-image: url('./img/btn_recruit.png');
  }
  .hdr_icon_recruit:hover{
    background-image: url('./img/btn_recruit_h.png');
  }
  .hdr_icon_recruit_selected{
    background-image: url('./img/btn_recruit_h.png');
  }



  .hdr_icon_contact{
    background-image: url('./img/btn_contact.png');
  }
  .hdr_icon_contact:hover{
    background-image: url('./img/btn_contact_h.png');
  }
  .hdr_icon_contact_selected{
    background-image: url('./img/btn_contact_h.png');
  }
  
  @media only screen and (max-width: 1200px) {
    /* For mobile phones: */
    .hdr_btn {display: none ;  }
    .hdr_btn_top  { display: inline-block ;  }    

  }

