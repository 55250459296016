.NewsdetailHeader {
    display: block;
    position: relative;

    height: auto;

    /*margin-bottom: 25px; /* try done by Body */

    text-align: left;
}

.NewsdetailHeader-Title{

    display: inline-block;
    position: relative;

    margin-left: 15px;
    margin-top: 30px;

    padding-left: 32px;
    /*padding-top: -332px; /* Not work */
    /*padding-bottom: 32px;*/

    left: 0px;
    top: 0px;
    width: 1169px;
    height: 30px;

    background-image: url('./img/cap.png');
    background-repeat: no-repeat;

    text-align: left;
    color: #000000;
    font-size: 20px;
    line-height: 20px;
}


.NewsdetailHeader-Date{

    display: block;
    position: relative;

    margin-left: 45px;
    margin-top: 15px;

    left: 0px;
    top: 0px;

    text-align: left;

}

.NewsdetailHeader-Icon-News{

    display: inline-block;
    position: relative;

    margin-right: 12px;

    left: 0px;
    top: 0px;
    width: 98px;
    height: 24px;

    background-image: url('../news/img/news_icon.png');
    background-repeat: no-repeat;

    vertical-align: top;
}

.NewsdetailHeader-Date-Txt{
    display: inline-block;
    position: relative;

    margin-left: 0px;
    margin-top: 0px;

    left: 0px;
    top: 0px;

    color: #000000;
    font-size: 18px;
    line-height: 24px;

    vertical-align: top;
}
@media only screen and (max-width: 1200px) { 
    .NewsdetailHeader-Title{
        width: 90vw;
        background-position: right;
        font-size: initial;
        margin-left: auto;
        padding: inherit;
        font-weight: 600;
    }
    .NewsdetailHeader-Date{
        margin: auto;
    }
}