
.npb_btn{
    display: inline-block;
  
    width: 31px;
    height: 31px;
    line-height: 31px;

    text-align: center;
    vertical-align: middle;
  
    font-size: 18px;
  
    background-size: 100% 100%;
  
    border: 0px solid red; /* #DEBUG */
  
    /*
  
    display: inline-block;
    position: absolute;
  
    vertical-align: bottom;
  
    z-index: 100;
    */
  }
  
  .npb_icon {
      background-image: url('./img/btnNum.png');
      color: #7c7c7c;
  }
  .npb_icon:hover {
      background-image: url('./img/btnNum_h.png');
      color: #111111;
  }
  .npb_icon_selected {
      background-image: url('./img/btnNum_h.png');
      color: #ffffff;
  }
  
  .npb_iconL {
      background-image: url('./img/btnNumLeft.png');
  }
  .npb_iconL:hover {
      background-image: url('./img/btnNumLeft_h.png');
  }
  
  .npb_iconR {
      background-image: url('./img/btnNumRight.png');
  }
  .npb_iconR:hover {
      background-image: url('./img/btnNumRight_h.png');
  }
  
  .npb_icon3dot {
      background-image: url('./img/btnNum3dot.png');
  }
  