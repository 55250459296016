.Privacy {
  color: gray;
  text-align: left;
}

.Privacy-Cap {
  position: relative;
  left: 15px;
  top: 15px;
}

.Privacy-Txt-Abs {
    color: black;
    text-align: left;
    font-size: 20px;
    font-weight: bold;

    padding-left: 50px;
    padding-right: 50px;
  }

.Privacy-Txt-Cap {
    color: black;
    text-align: left;
    font-size: 20px;
    font-weight: bold;

    padding-left: 55px;
  }

.Privacy-Txt {
    display: inline-block;
    position: relative;

    border: 0px solid red;

    color: #6e6e6e;
    text-align: left;
    font-size: 20px;

    left: 75px;
    width: 1045px;
    /*
    text-indent: 0px;
    padding-left: 75px;
    padding-right: 75px;
    */
  }

.Privacy-Txt-Mail {
    color: #1e3f83;
    text-decoration: none;
  }

.Privacy-Txt-Date {

    display: inline-block;
    position: relative;
    left: 55px;

    color: black;
    text-align: left;
    font-size: 22px;
    font-weight: bold;
  }
@media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .Privacy-Txt {
      width: 85vw;
      left: 10vw;
    }
    .Privacy-Txt-Cap {
      width: 90vw;
      padding:5vw;
      display: block;
    }
    .Privacy-Cap {
      left: -1px;
    }
}