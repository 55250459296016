

.TopButtons {
    display: inline-block;
    position: absolute;
    left: 735px;
    top: 18px;
    width: 420px;
    height: 375px;
}

.TopButtons-Btn-Company {
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 205px;
    height: 181px;

    background-image: url('./img/btn_company.png');
}
.TopButtons-Btn-Company:hover {
    background-image: url('./img/btn_company_h.png');
}

.TopButtons-Btn-Recruit {
    display: inline-block;
    position: absolute;
    left: 209px;
    top: 0px;
    width: 205px;
    height: 181px;

    background-image: url('./img/btn_recruit.png');
}
.TopButtons-Btn-Recruit:hover {
    background-image: url('./img/btn_recruit_h.png');
}

.TopButtons-Btn-Service {
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 185px;
    width: 414px;
    height: 165px;

    background-image: url('./img/btn_service.png');
}
.TopButtons-Btn-Service:hover {
    background-image: url('./img/btn_service_h.png');
}
@media only screen and (max-width: 1200px) {
    .TopButtons {
        display: block;
        top: 18px;
        width: 100%;
        position: initial;
        left: initial;
        height: auto;
    }
    .TopButtons-Btn-Company,.TopButtons-Btn-Recruit {

        display: inline-block;
        top: 0px;
        width: 49%;
        max-width: 177px;
        max-height: 156px;
        background-size: contain;
        position: initial;
        margin: 2px;
        left: initial;
    }
    .TopButtons-Btn-Service {
        display: block;
        position: initial;
        top: 185px;
        width: 98%;
        max-width: 358px;
        max-height: 142px;
        background-size: contain;
    margin: auto;
        background-image: url('./img/btn_service.png');
    }
}