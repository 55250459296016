.FooterFYJ {

    position: relative;

    background-color: black;

    background-image: url('./img/bg.png');
    background-size: 1920px 93px;
    background-position: center 0px;
    background-attachment: scroll;
    background-repeat: no-repeat;

    /*left: 0px;*/
    top: 0px;
    width: 100vw; /*1920px;*/
    height: 93px;
  
    text-align: center;

    z-index: 10;
}
.FooterFYJ .Footer_btn span{
  visibility: hidden;
}
.FYJ_copyright{
display: none;
}

.Footer_btn {
    display: inline-block;
    position: absolute;
  
    z-index: 20;
  }

.Footer_btn_privacy{
    /*left: 228px; /*588-360px;*/
    left: 357px; /*717-360px;*/
    top: 15px;
    width: 220px;
    height: 36px;
  }

.Footer_btn_contract{
    /*left: 463px;*/
    left: 592px; /* 952-360 */
    top: 15px;
    width: 99px;
    height: 36px;
  }

.Footer_btn_law_shop{
    left: 588px;
    top: 15px;
    width: 220px;
    height: 36px;

    visibility: hidden; /* HCY 2019OCT15 by FYJ */
  }

.Footer_btn_contact{
    /*left: 820px;*/
    left: 710px; /* 1070 - 360 */
    top: 15px;
    width: 160px;
    height: 36px;
  }
@media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .FYJ_copyright{
      display: block;
      color: white;
      }    
    .Footer_btn_privacy,.Footer_btn_contract,.Footer_btn_law_shop,.Footer_btn_contact{
      color: white;
      left: initial;
      width: 100vw;
      position: initial;
      margin: 2px;
    }
    .FooterFYJ .Footer_btn_law_shop{
      display: none;
    }
    a:link{text-decoration: none;    }

    .FooterFYJ .Footer_btn span{
      visibility: visible;
      font-weight: 800;
    }
    .FooterFYJ  {
      position: relative;
      background: initial;
      background-color: black;
      top: 0px;
      width: 100vw; /*1920px;*/
      height: 100%;
    
      text-align: center;
  
      z-index: 10;
  
    }
    .middle2{
      width: inherit;
    }

  }