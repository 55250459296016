
.ContactConfirm{
    text-align: center;

    margin-bottom: 55px;
}

  
  .ContactConfirm-table {
  
    display: inline-block;
    position: relative;

    left: 0px;
    top: 0px;
    margin-top: 16px; /* 28px; (!) affected by border-spacing */
  
    width:auto;
    
    border-collapse: separate;
    border-spacing: 0px 12px;
 
  }
  
  .ContactConfirm_td_ttl{
    color: #ffffff;
    background-color: #8c1d23;
  
    width: 230px; /* 255px (!) padding 25px  */
    text-align: left;
  
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  
    font-size: 20px;
    font-weight: bold;
  }

  .ContactConfirm_td_ttl_mail_re{
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 24px;
  }
  
  .ContactConfirm_td_ctt{
    color: #000000;
    background-color: #ebebeb;

    width: 795px; /* 845px (!) padding 30/20px */
    text-align: left;
  
    padding-left: 30px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  
    font-size: 20px;
    font-weight: normal;

    overflow: hidden;

    word-break: break-all;
  }


.Contact-Btn-GoForm {
    display: inline-block;
    position: relative;
    left: 0px;
    top: 0px;
    width: 192px;
    height: 63px;

    background-image: url('./img/btn_go_form.png');
}
.Contact-Btn-GoForm:hover {
    background-image: url('./img/btn_go_form_h.png');
}

.ContactCfm-Btns{
    display: flexbox;
    margin-top: 25px;
}

.ContactCfm-Btn{
    margin-left: 53px;
    margin-right: 53px;
}

.Contact-Btn-Send {
    display: inline-block;
    position: relative;
    left: 0px;
    top: 0px;
    width: 192px;
    height: 63px;

    background-image: url('./img/btn_send.png');
}
.Contact-Btn-Send:hover {
    background-image: url('./img/btn_send_h.png');
}
