.ServiceInfo{
  display: inline-block;
  position: relative;

  width: 1170px; /*1166px;*/
  height: auto; /*344px;*/

  border-width: 1px;

  border-color: #c2c2c2;

  border-top-style: none;
  border-right-style: none; /*dotted*/
  border-bottom-style: solid;
  border-left-style: none;

  margin-top: 38px;
  padding-bottom: 33px;

}

.SI-Hidden{
    visibility: hidden;
}

.SI-Pic{
    display: inline-block;
    position: relative;

    left: 0px;
    top: 0px;
    width: 572px;
    height: 304px;
}

.SI-Title-Area{
    display: inline-block;
    position: relative;

    left: 0px;
    top: 0px;
}

.SI-Title{
    display: inline-block;
    position: relative;

    left: 0px;
    top: 0px;
    width: 488px; /*395px; margin-left 5px and jitter for platform icon */
    height: 21px;

    margin-left: 7px;

    text-align: left;
    color: #000000;
    font-size: 18px; /*24px;*/
    font-weight: bold;
}

.SI-Bar{
    display: inline-block;
    position: relative;

    /*border-color: #a4080f;*/
    border: 3px solid #a4080f;
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: solid;
    border-left-style: none;

    left: 0px;
    top: 0px;
    width: 544px;
    /*height: 0px;*/

    margin-bottom: 10px;

    /*background-image: url('./img/bar_game.png');*/
}

/*
.SI-SubTitle{
    display: inline-block;
    position: absolute;

    left: 590px;
    top: 50px;
    width: 520px;
    height: 25px;

    text-align: left;
    color: #646464;
    font-size: 18px;
}
*/

.SI-Icon-Platform{
    display: inline-block;
    position: absolute; /* HCY 2019OCT16 absolute for long SI-Title */
    left: 390px;
    top: 1px;
    width: 150px;
}

.SI-Icon{
    display: inline-block;
    position: relative;
    left: 0px;
    top: 0px;

    width: 45px;
    height: 21px;

    margin-right: 5px;
}
.SI-Icon-Android {
    background-image: url('./img/icon_android.png');
}
.SI-Icon-IOS {
    background-image: url('./img/icon_ios.png');
}
.SI-Icon-PC {
    background-image: url('./img/icon_pc.png');
}

.SI-table {
    display: inline-block;
    position: relative;

    width:auto;

    text-align: left;

    border-collapse: separate;
    border-spacing: 10px 0px;
}

.SI-td-L {
    /*display: inline-block;*/
    display: table-cell;
    /*position: relative;*/

    width: 573px;

    /*vertical-align: top;*/
}

.SI-td-R {
    display: table-cell;

    width: 550px; /*545px;*/

    text-align: left;
    /*vertical-align: top;*/
}
@media only screen and (max-width: 1200px) {    
    /* For mobile phones: */
    .ServiceInfo{
        width: 100vw;
    }
    img.SI-Icon-Null{
        display: none;
    }
    .SI-table >tbody>tr{
        display: grid;
    }
    .SI-td-R ,.SI-td-L {
        width: 100vw;
        max-width: 572px;
        text-align: center;
    }
    .SI-Pic{
        width: 90vw;
        max-width: 572px;
        background-size: contain;
        background-repeat: no-repeat;
        max-height: 304px;
        height: 60vw;
    }
    .SI-Title-Area{
        width: 100% ;
        height: 21px;
        display: inline-flex;
    }
    .SI-Title[custom="long13px"]{
        font-size:13px;
    }
    .SI-Title[custom="long11pxnobr"]{
        font-size:11px;
    }
    .SI-Title[custom="long11pxnobr"]>br{
        display: none;
    }
    .SI-Title[custom="long-20t"]{
        top: -20px;
    }

    .SI-Title{
        width: max-content;
        max-width: 488px;
        float: left;
        margin-left: initial;
        padding-left: 6px;
        margin-right: 3px;
    }

    .SI-Icon-Platform{
        width: max-content;
        max-width: 150px;
        left: initial;
        height: 100%;
        position: relative;
        text-align: left;
    }
    .SI-Icon{
        margin-right: 1px;
        width: calc(45px * 0.8);
        height: calc(21px * 0.8);
        top: 3px;
    }
    .SI-Bar{
        width: calc(100%);
        margin-left: 6px;
        float: left;
    }
    .SI-Title::after,.SI-Bar::after{
        content:"";
        display: block;
        clear: both;
    }

}